.toastContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 2rem 3.75rem 2rem 1.5rem;

  color: var(--red-500);

  border-radius: 6px;

  > span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: inherit;
  }

  > button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    background: transparent;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
}

.error {
  color: var(--red-500);
  background: var(--red-100);
}

.success {
  color: var(--green-500);
  background: var(--green-100);
}