.overlay {
  background: rgba($color: #000000, $alpha: 0.85);
  position: fixed;
  inset: 0;
  animation: overlayShow 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
