.container {
  .labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.25rem;

    > div {
      span {
        font-size: 0.75rem;
        line-height: 155%;
        color: #DEE2E6;
      }

      &:nth-child(2) {
        text-align: end;
      }
    }
  }
}