.horizontal-slider {
  width: 100%;
  height: 1rem;
}

.slider-thumb {
  position: relative;
  width: 1rem;
  height: 1rem;
  
  background: #FFF;
  border-radius: 50%;

  cursor: pointer;
  filter: drop-shadow(0px 0px 6px rgba(52, 58, 64, 0.10));

  > span {
    position: absolute;
    left: 50%;
    bottom: calc(-100% - 4px);
    transform: translate(-50%);
    
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 155%;
    color: #343A40;
  }
}

.slider-thumb.active {
  outline: 2px solid var(--blue-500);
}

.slider-track {
  position: relative;
  border-radius: 6px;
  background: var(--blue-500);
  top: 0.25rem;
  height: 0.5rem;
}

.slider-track.slider-track-1 {
  background: #E9ECEF;
}
