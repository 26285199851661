.buttonRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;

  width: 100%;
  padding: 1.25rem 2.5rem;

  border-radius: 6px;
  background: var(--blue-500);
  border: 1px solid var(--blue-500);

  transition: all .2s;

  &:hover {
    background: var(--blue-600);
  }

  > img {
    animation: spinner 1.5s linear infinite;
  }

  &.loading {
    border: none;
    background: rgba($color: #534ED9, $alpha: 0.5);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}