.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 0.75rem 1.5rem 1.5rem;

  border-radius: 6px;
  background: #FFFFFF;
  border: 1px solid #F1F3F5;

  transition: all .2s;

  &.active {
    background: rgba(#534ED9, 0.05);
    border: 1px solid var(--blue-500);
  }

  .checkbox {
    width: 16px;
  }
}