.inputContainer {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: #495057;

  transition: all .2s;

  &.error {
    color: var(--red-500);

    > input {
      color: #495057;
      border-color: var(--red-500);
    }

    > span {
      color: var(--red-500);
    }
  }

  &:focus-within {
    color: var(--blue-500);
  }

  > input {
    display: flex;
    align-items: center;

    width: 100%;
    padding: 1rem 1.25rem;
    margin-top: 0.5rem;

    font-size: 1rem;
    color: #495057;

    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #DEE2E6;

    transition: all .2s;

    &:disabled {
      cursor: not-allowed;
    }

    &:focus {
      border: 1px solid var(--blue-500);
    }

    &::placeholder {
      font-weight: 400;
      color: #C1C6CC;
    }
  }

  > span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #A9AEB5;
  }
}