.otpInput {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;

  border-radius: 6px;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;

  &::placeholder {
    font-weight: 400;
    color: #DEE2E6;
  }

  &:focus {
    border: 1px solid var(--blue-500);
  }
}

.otpInputSeparator {
  width: 0.5rem;
}