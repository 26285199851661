.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.5rem 7rem;

  border-bottom: 1px solid #E9ECEF;

  @media (max-width: 1024px) {
    padding: 1.5rem;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 2.5rem;

    @media (max-width: 1024px) {
      gap: 1rem;
    }

    a {
      &:has(div) {
        &:hover {
          filter: none;
        }
      }
      
      div {
        width: 32px;
        height: 29px;
        background: var(--blue-100);
        border-radius: 50%;
      }
    }

    > nav {
      display: flex;
      align-items: center;
      gap: 2rem;

      @media (max-width: 1024px) {
        display: none;
      }

      a {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #868E96;

        &.active {
          font-weight: 500;
          color: #495057;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;

    > nav {
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #495057;

        &.bold {
          font-weight: 600;
        }

        &.withSeparator {
          gap: 0;

          &::after {
            content: '';
            width: 1px;
            height: 1rem;
            background: #CED4DA;
            margin: 0 2rem;
  
            @media (max-width: 425px) {
              margin: 0 0.75rem;
            }
          }
        }
      }
    }
  }
}

.profile-trigger {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  border: 0;
  background: transparent;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;
    border: 1px solid #ADB5BD;
  }

  .infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    strong {
      display: block;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #343A40;
    }

    span {
      display: block;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #868E96;
    }
  }
}

.profile-content {
  width: 10.75rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  > button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    width: 100%;

    font-weight: 500;
    font-size: 0.875rem;
    color: var(--red-500);

    border: 0;
    background: transparent;

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.language-trigger {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  border: 0;
  background: transparent;
}

.language-content {
  border-radius: 4px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  > button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0.5rem;

    border: 0;
    background: transparent;

    & + button {
      border-top: 1px solid #F2F2F2;
    }
  }
}

.currencies-trigger {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  border: 0;
  background: transparent;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }

  > span {
    font-weight: 500;
    font-size: 0.875rem;
    color: #495057;

    span {
      color: #868E96;
    }
  }
}

.currencies-content {
  border-radius: 4px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  > button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0.5rem;

    border: 0;
    background: transparent;

    & + button {
      border-top: 1px solid #F2F2F2;
    }

    > span {
      font-weight: 500;
      font-size: 0.875rem;
      color: #495057;

      span {
        color: rgba(0, 0, 0, 0.50);
      }
    }
  }
}

.mobile-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  
  border: 0;
  background: transparent;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
}

.mobile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100dvw;
  height: 100dvh;
  padding: 1.75rem 1.5rem;

  border-radius: 0;

  .close {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    background: transparent;
  }

  > nav {
    width: 100%;

    > div {
      display: block;
      width: 100%;
      padding: 2rem 0;

      &:first-child {
        padding-top: 0;
      }

      & + div {
        border-top: 1px solid #E9ECEF;
      }

      a {
        font-weight: 500;
        font-size: 1.125rem;
        color: #495057;
      }
    }
  }

  > footer {
    position: absolute;
    left: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: calc(100dvw - 1.5rem * 2);
    margin: 1.5rem;
    padding-top: 1rem;

    border-top: 1px solid #E9ECEF;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
