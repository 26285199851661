.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;

  strong {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #495057;
  }

  span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #868E96;
  }
}