.formContainer {
  margin-top: 2rem;
  width: 27.25rem;

  @media (max-width: 768px) {
    width: calc(100dvw - 1.5rem * 2);
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > a {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #868E96;
        text-decoration-line: underline;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.875rem;
    margin-top: 2rem;

    > button {
      width: 100%;
    }

    > span {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #495057;

      > a {
        font-weight: 500;
        color: inherit;
      }
    }
  }
}