.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #868E96;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0;
    border-radius: 4px;
    border: 1px solid #CED4DA;

    transition: all .2s;

    &.active {
      background: var(--blue-500);
      border: 1px solid var(--blue-500);
    }
  }
}