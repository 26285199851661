.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 32rem;
  width: 100%;

  text-align: center;

  > strong {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 500;
    color: #343A40;
    margin-top: 2rem;
  }

  > p {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    color: #868E96;
    margin-top: 0.75rem;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation {
  animation: 0.5s slide;
}

@keyframes slide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
