.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 6px;
  background: #FFFFFF;

  animation: contentShow 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}